<script setup lang="jsx">
import {auth} from "@/services/auth/firebase/firebase.service.js";
import {GoogleAuthProvider, signInWithPopup} from "@firebase/auth";
import {getSocialLoginToken} from "@/services/auth/AuthService";
import {useHttps} from "@/plugins/http";


const https = useHttps();

const props = defineProps({
  authLoginForm: Object,
})
const emit = defineEmits([
  "is:social-loading",
  "is:social-login",
  "is:unsuccess-login",
]);

const startLoader = () => {
  emit("is:social-loading", true);
};

const closeLoader = () => {
  emit("is:social-loading", false);
};

const tryRecaptcha = async () => {
  if (!props.authLoginForm) return null;
  return props.authLoginForm.getRecaptchaToken();
};
const loginFromAPI = async ({token}) => {
  const recaptchaToken = await tryRecaptcha();
  const data = new URLSearchParams();
  data.append("googleToken", token);
  data.append("g-recaptcha-response", recaptchaToken);
  const response = await https.post("/Login", data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  console.log("response", response);
  closeLoader();
  if (response.data.smsonay || response.data.mailonay) {
    emit("is:unsuccess-login", response);
    return;
  }
  if (!response.data.success) {
    emit("is:unsuccess-login", {
      message: response.data.message,
    });

    return;
  }
  emit("is:social-login", response.data);
};
const getGoogleProvider = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  provider.addScope("https://www.googleapis.com/auth/userinfo.email");
  provider.setCustomParameters({locale: "tr"});
  return provider;
};

const parseGoogleCredential = (user) => {
  return {
    userId:
        user.providerData && !!user.providerData[0] && !!user.providerData[0].uid
            ? user.providerData[0].uid
            : user.uid,
    email: !!user.email
        ? user.email
        : !!user.providerData &&
        !!user.providerData[0] &&
        !!user.providerData[0].email
            ? user.providerData[0].email
            : null,
    avatar: user.photoURL,
    username: "",
    name: user.displayName,
  };
};

const loginWithSocialApp = async () => {
  startLoader();
  const response = await signInWithPopup(auth, getGoogleProvider());
  const user = parseGoogleCredential(response.user);
  const data = await getSocialLoginToken({
    ...user,
    providerId: "google",
  });
  console.log("token", data);
  if (!data.success) {
    closeLoader();
    emit("is:unsuccess-login", {
      message: data.message,
    });
    return;
  }
  return loginFromAPI({
    token: data.token,
  });
};

</script>

<template>
  <ul class="social-login">
    <li>
      <a

          @click="loginWithSocialApp()"
      >
        <img src="/img/static/google.png" alt="google-icon"/>
        <span>Google ile Giriş Yap</span>
      </a>
    </li>
  </ul>
</template>

<style scoped>
.social-login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  li {
    margin: 0 10px;
    list-style: none;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      border-radius: 5px;
      background-color: var(--default-background-color);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: var(--default-link-color);
      }
    }
  }
}
</style>