<script setup>
import { ref, computed } from "vue";
import VueTypes from "vue-types";
import Recaptcha from "@/features/auth/Recaptcha.vue";

const props = defineProps({
  username: VueTypes.string.required,
  password: VueTypes.string.required,
  loading: VueTypes.bool.required,
  captchaVisible: VueTypes.bool.required,
  privacyContent: VueTypes.string,
});
const emit = defineEmits([
  "update:username",
  "update:password",
  "update:captchaVisible",
  "is:captcha-verify",
  "is:finish",
]);

const recaptchaComponent = ref(null);
const visible = ref(false);

const username = computed({
  get: () => props.username,
  set: (v) => emit("update:username", v),
});
const password = computed({
  get: () => props.password,
  set: (v) => emit("update:password", v),
});
const captchaVisible = computed({
  get: () => props.captchaVisible,
  set: (v) => emit("update:captchaVisible", v),
});

const resolveRecaptchaToken = () => {
  if (!recaptchaComponent.value) return;
  captchaVisible.value = true;
  recaptchaComponent.value.resolveToken();
};

const getRecaptchaToken = async () => {
  if (!recaptchaComponent.value) return;
  return recaptchaComponent.value.executeToken("login");
};

const register = () => {
  window.open("https://www.itemsatis.com/kayit.html", "_blank");
};

defineExpose({
  resolveRecaptchaToken,
  getRecaptchaToken,
});
</script>

<template>
  <section class="login-form-section">
    <a-form
      id="login"
      layout="vertical"
      :model="{ username, password }"
      class="base-form"
      @finish="$emit('is:finish')"
      validateTrigger="blur"
      autocomplete="on"
    >
      <is-spin :loading="loading">
        <a-form-item
          name="username"
          class="mt-4 with-left-icon"
          :rules="[
            {
              required: true,
              message: 'Lütfen Kullanıcı Adınızı boş bırakmayın.',
            },
          ]"
          label="Kullanıcı Adı"
        >
          <a-input
            v-model:value="username"
            size="large"
            :bordered="false"
            autocomplete="username"
          >
            <template #addonBefore>
              <i
                class="bx bx-user font-size-135 icon-color cursor-pointer"
              ></i> </template
          ></a-input>
        </a-form-item>
        <a-form-item
          name="password"
          class="with-left-icon"
          :rules="[
            {
              required: true,
              message: 'Lütfen Şifrenizi boş bırakmayın.',
            },
          ]"
          label="Şifre"
        >
          <a-input-password
            v-model:value="password"
            size="large"
            :bordered="false"
            autocomplete="current-password"
          >
            <template #addonBefore>
              <i class="bx bx-lock font-size-135 icon-color cursor-pointer"></i>
            </template>
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-button class="purple-button" size="large" block html-type="submit"
            >Giriş Yap</a-button
          >
        </a-form-item>
        <!-- <a-form-item class="mb-3">
          <p class="mb-2 is-description text-center">
            Hala bir üyeliğiniz yok mu?
          </p>
          <a-button class="secondary-button" block @click="register"
            >Hemen Kayıt Ol</a-button
          >
        </a-form-item> -->
        <!-- Hızlı giriş

    <a-form-item class="no-margin">
      <p class="form-divider no-margin">
        <span>ya da</span>
      </p>
    </a-form-item>
    <a-form-item> </a-form-item>
    -->
      </is-spin>
    </a-form>
    <Recaptcha
      ref="recaptchaComponent"
      v-model:visible="captchaVisible"
      @is:verify="$emit('is:captcha-verify', $event)"
    ></Recaptcha>
  </section>

  <p style="font-size: 12px; color: #9aa1b9; text-align: center; margin-top: 2">
    Giriş yapmanız durumunda
    <span
      class="hover-privacy"
      @click="visible = true"
      style="text-decoration: underline; cursor: pointer"
      >Gizlilik Sözleşmesini</span
    >
    kabul etmiş olursunuz.
  </p>

  <div v-if="visible">
    <a-modal
      :visible="visible"
      title="Gizlilik Sözleşmesi"
      cancel-text="Kapat"
      @cancel="visible = false"
      @ok="visible = false"
    >
      <div
        style="color: #9aa1b9; max-height: 400px; overflow-y: auto"
        v-html="props.privacyContent"
      ></div>
    </a-modal>
  </div>
</template>

<style scoped>
.hover-privacy:hover {
  color: #b5b9c9;
}
</style>
