import {useHttps} from "@/plugins/http";

class AuthService {
    #https;

    constructor() {
        this.#https = useHttps();
    }

    setFcmToken = async ({token}) => {
        const response = await this.#https
            .post("/updateFcmToken", `Token=${token}`)
            .catch(this.#onError);
        if (response && response.data && response.data.success) {
        }
    };

    #onError = (error) => {
    };
}

export const getSocialLoginToken = async ({
                                              providerId, userId, email, avatar, username, name,
                                          }) => {
    const https = useHttps();
    const postData = `providerId=${providerId}&user[id]=${userId}&user[email]=${encodeURIComponent(email)}&user[avatar]=${avatar}&user[username]=${username}&user[name]=${name}`;
    const response = await https.post("/socialLoginToken", postData);
    if (response && response.data && response.data.success && response.data.token) {
        return {success: true, token: response.data.token};
    }
    return {success: false, message: response.data.message};
};

const authService = new AuthService();
export const useAuthService = () => authService;
