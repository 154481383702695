import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCnSK9D35IB-cSL7ZHqddS86ulJndg7H3A",
    authDomain: "itemsatis-e98e6.firebaseapp.com",
    databaseURL: "https://itemsatis-e98e6.firebaseio.com",
    projectId: "itemsatis-e98e6",
    storageBucket: "itemsatis-e98e6.appspot.com",
    messagingSenderId: "741027425776",
    appId: "1:741027425776:web:d7826566eca0ea269ec135",
    measurementId: "G-ZN0SH1X916"
};
const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
